/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cloud_logic_custom": [
        {
            "name": "adminApi",
            "endpoint": "https://pr1z6auunh.execute-api.eu-west-1.amazonaws.com/develop",
            "region": "eu-west-1"
        },
        {
            "name": "dialogoemailservice",
            "endpoint": "https://sk307yhrud.execute-api.eu-west-1.amazonaws.com/develop",
            "region": "eu-west-1"
        },
        {
            "name": "dialogolambda",
            "endpoint": "https://15rdfqnkd8.execute-api.eu-west-1.amazonaws.com/develop",
            "region": "eu-west-1"
        },
        {
            "name": "dialogostepstripe",
            "endpoint": "https://d3heowo4dj.execute-api.eu-west-1.amazonaws.com/develop",
            "region": "eu-west-1"
        },
        {
            "name": "dialogostripeapi",
            "endpoint": "https://69tbczntwi.execute-api.eu-west-1.amazonaws.com/develop",
            "region": "eu-west-1"
        },
        {
            "name": "dialogozoomapi",
            "endpoint": "https://u72pz98qa9.execute-api.eu-west-1.amazonaws.com/develop",
            "region": "eu-west-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://gixhh7sbxzaz7dmwctn2ya2dga.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-west-1:5c390589-e684-4d24-a9cf-a178e0d66dcd",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_PVb97focM",
    "aws_user_pools_web_client_id": "4iqi3hden3ctf67f0en3j444oq",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "FAMILY_NAME",
        "GIVEN_NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "dialogofb11a7a0cc724e9a85073cfb12d1367a-develop",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
